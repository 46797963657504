<template>
  <div class="type">
    <div class="info_1">
      <van-image width="80"
                 height="100"
                 :src="option.pictureUrl"
                 fit="contain"
                 v-if="option.pictureUrl" />
      <van-image width="80"
                 height="100"
                 src="http://www.fkxrmyy.com/wxxcx/wx/image/doc.png"
                 fit="contain"
                 v-if="!option.pictureUrl" />
      <div class="content_1">
        <div>科室：{{option.deptName}}</div>
        <div>姓名：{{option.doctorName}}</div>
        <div>职称：{{option.doctorTitle}}</div>
      </div>

    </div>
    <div class="intro">
      <div class="title">个人简介</div>
      <div :class="more ? 'content' : 'more' "
           @click="showMore">{{option.doctorIntrodution === ''?'暂无信息':option.doctorIntrodution}}</div>
    </div>
    <div class="choose_1">
      <div class="item item1">

        <img src="../../assets/tuwen.png"
             alt="图文">
        <p>图文问诊</p>

      </div>
      <div class="item"
           @click="choose(1)">
        <img src="../../assets/yuyue.png"
             alt="预约挂号">
        <p>预约挂号</p>
      </div>
      <div class="item"
           @click="choose(2)">
        <img src="../../assets/tel.png"
             alt="电话问诊">
        <p>电话问诊</p>
      </div>
    </div>

    <div class="tip_1">
      温馨提示： 名医是医院在职医生，利用休息时间为您服务，未及时为您服务，敬请谅解。
    </div>
    <div class="bottom_1">
      <van-button type="info"
                  style="width: 100%;"
                  @click="navDetail">图&nbsp;&nbsp;文&nbsp;&nbsp;问&nbsp;&nbsp;诊</van-button>
      <!-- <van-button type="primary"
                  @click="back">返&nbsp;&nbsp;回</van-button> -->
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import { setTimeout } from 'timers';
export default {
  name: "Type",
  data () {
    return {
      pictureUrl: "",
      deptName: '',
      doctorName: '',
      regType: '',
      doctorIntrodution: '',
      more: false,
      option: {}
    }
  },
  created () {
    this.option = this.$route.query
  },
  methods: {
    showMore () {
      this.more = !this.more;
    },
    choose (type) {
      if (type == 1) {
        this.$router.push({
          path: "/register/doctor",
          query: {
            dateIndex: this.option.dateIndex,
            doctorCode: this.option.doctorCode,
            deptCode: this.option.deptCode,
            tabIndex: this.option.tabIndex,
            timeSlot: this.option.timeSlot,
            deptName: this.option.deptName,

          }
        });
      } else if (type == 2) {
        this.$toast.fail("暂未开通")
      }
    },
    navDetail () {
      this.$router.push({
        path: "/register/wen",
        query: {
          doctorCode: this.option.doctorCode,
          deptName: this.option.deptName,
          deptCode: this.option.deptCode,
          tabIndex: this.option.tabIndex,
          timeSlot: this.option.timeSlot,
          doctorName: this.option.doctorName,

        }
      });
    }
  },

}
</script>

<style lang="less" scope>
.info_1 {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 20px 30px;
  .content_1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    margin-left: 30px;
    div {
      margin-top: 16px;
    }
  }
  .collect {
    position: absolute;
    top: 50%;
    right: 40px;
    transform: translateY(-40%);
    font-size: 36px;
    padding: 20px;
  }
}
.intro {
  .title {
    font-size: 40px;
    padding: 20px 30px;
    border-bottom: 1px solid #f5f5f5;
  }
  .content {
    font-size: 36px;
    color: rgba(87, 105, 123, 1);
    text-indent: 1em;
    padding: 10px 30px;
    line-height: 60px;
    border-bottom: 1px solid #f5f5f5;
  }
  .more {
    font-size: 36px;
    color: rgba(87, 105, 123, 1);
    text-indent: 1em;
    padding: 10px 30px;
    line-height: 60px;
    border-bottom: 1px solid #f5f5f5;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.choose_1 {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  .item {
    width: 30%;
    border: 2px solid #ccc;
    border-radius: 3px;
    text-align: center;
    align-items: center;
    padding: 60px 0;
    p {
      font-size: 28px;
    }
    img {
      width: 50%;
    }
  }
  .item1 {
    position: relative;
    border: 3px solid #1989fa;
    .van-icon {
      position: fixed;
      right: 520px;
      top: 580px;
      font-size: 30px;
    }
  }
}
.tip_1 {
  margin-top: 20px;
  font-size: 32px;
  padding: 10px;
}
.bottom_1 {
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 100%;
  height: 150px;
  background-color: white;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 30px;
}
</style>
